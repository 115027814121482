// user
export const USER_SET_EMAIL = "USER_SET_EMAIL";
export const USER_SET_NAME = "USER_SET_NAME";
export const USER_SET_CLASS = "USER_SET_CLASS";
export const USER_SET_AVATAR = "USER_SET_AVATAR";
export const USER_IS_ADMIN = "USER_IS_ADMIN";

// auth
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_REGISTERED = "SET_REGISTERED";

// system info
export const SYSTEM_SET_VERSION = "SYSTEM_SET_VERSION";
export const SYSTEM_SET_HASH = "SYSTEM_SET_HASH";
export const SYSTEM_SET_HASH_DATE = "SYSTEM_SET_HASH_DATE";

// event
export const EVENT_SET_DATE = "EVENT_SET_DATE";
export const EVENT_SET_SWISH_PHONE = "EVENT_SET_SWISH_PHONE";
export const EVENT_SET_SWISH_NAME = "EVENT_SET_SWISH_NAME";
